





















import { Research, Respondent } from '@app/models';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import RespondentTable from '../../components/5-table/respondent-table.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';

@Component({
  components: { ResearchTargetTabs, RespondentTable },
})
export default class ResearchRespondents extends Vue {
  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public respondents!: Respondent[];

  @Prop()
  public targets!: string[];

  private target = this.targets[0] || '';

  public get showOthers() {
    return this.respondents.some(respondent => {
      return !this.targets.some(t => respondent.target.startsWith(t));
    });
  }

  public get targetsExtra() {
    // Add an extra tab with others since the CSV export allow arbitrary input
    return [...this.targets, 'others'];
  }

  public get filteredRespondents() {
    return this.target === 'others'
      ? this.otherRespondents
      : this.respondents.filter(r => r.target.startsWith(this.target));
  }

  public get otherRespondents() {
    return this.respondents.filter(r =>
      this.targets.every(t => !r.target.startsWith(t))
    );
  }

  created() {
    this.target = this.targets[0] || '';
  }

  @Watch('showOthers')
  public watchShowOthers() {
    if (!this.showOthers && this.target === 'others')
      this.target = this.targets[0];
  }

  // public newCol: string = '';
  // public customFields: any[] = [];

  // public currentPage = 1;
  // public perPage = 10;

  // public add: Respondent = {} as any;
  // public edit: Respondent = {} as any;

  // public textFile!: string | null;

  // public get formIsComplete() {
  //   return this.isComplete(this.add);
  // }

  // public get editIsComplete() {
  //   return this.isComplete(this.edit);
  // }

  // public get fields(): Array<any> {
  //   return [
  //     {
  //       key: 'email',
  //       label: this.$t('account:settings.email'),
  //       sortable: true
  //     },
  //     {
  //       key: 'name',
  //       label: this.$t('account:settings.name-last'),
  //       sortable: true
  //     },
  //     {
  //       key: 'gender',
  //       label: this.$t('account:settings.gender'),
  //       sortable: true
  //     },
  //     {
  //       key: 'target',
  //       label: this.$t('research:details.target-audience'),
  //       sortable: true
  //     },
  //     ...this.customFields,
  //     {
  //       key: 'actions',
  //       label: '',
  //       thClass: 'th-actions_',
  //       tdClass: 'td-actions'
  //     }
  //   ].filter(field => {
  //     return field.key === 'target' ? this.target : true;
  //   });
  // }

  // public get target() {
  //   return (
  //     this.$user.license === 'pro' && this.value.target.includes('headquarters')
  //   );
  // }

  // public created() {
  //   this.add = this.empty();
  //   this.edit = { ...this.empty() };
  // }

  // public onTabChange() {}

  // public async addCol() {
  //   const key = this.newCol;
  //   const newCol = {
  //     key: key,
  //     label: key,
  //     sortable: true
  //   };
  //   this.customFields.push(newCol);

  //   this.add = this.empty();
  //   this.edit = { ...this.edit, [key]: '' };
  //   this.newCol = '';

  //   await this.$firebase
  //     .doc(`research/${this.value.id}`)
  //     .update({ customFields: this.customFields });

  //   this.$emit('respondent:edit', true);
  // }

  // public async addRespondent() {
  //   if (this.respondents.find(r => r.email === this.add.email)) {
  //     this.$bce.message('Deze gebruiker is al toegevoegd aan de lijst.');
  //     this.add = this.empty();
  //     return;
  //   }

  //   await this.$firebase
  //     .doc(`research/${this.value.id}/respondent/${this.add.id}`)
  //     .set(this.add);

  //   this.add = this.empty();
  // }

  // public async deleteRespondent(respondent: Respondent) {
  //   await this.$firebase
  //     .doc(`research/${this.value.id}/respondent/${respondent.id}`)
  //     .delete();
  // }

  // public async editRespondent(respondent: Respondent) {
  //   this.edit = { ...respondent };
  //   this.$emit('respondent:edit', true);
  // }

  // public async updateRespondent(respondent: Respondent) {
  //   await this.$firebase
  //     .doc(`research/${this.value.id}/respondent/${respondent.id}`)
  //     .set(respondent);

  //   this.edit = this.empty();
  //   this.$emit('respondent:edit', false);
  // }

  // public async importCsv(event: any) {
  //   const file = event.target.files[0];
  //   const data = await this.parseCsv(file);
  //   console.log(data);

  //   const batch = this.$firebase.batch();

  //   for (const entry of data) {
  //     let respondent: Respondent = { ...this.empty(), ...entry };
  //     const exists = this.respondents.find(r => r.email === respondent.email);
  //     if (exists) {
  //       respondent = { ...exists, ...entry };
  //     }

  //     const path = `research/${this.value.id}/respondent/${respondent.id}`;
  //     batch.set(this.$firebase.doc(path), respondent);
  //   }

  //   await batch.commit();
  // }

  // public createTextFile(text: string) {
  //   var data = new Blob([text], { type: 'text/plain' });

  //   // If we are replacing a previously generated file we need to
  //   // manually revoke the object URL to avoid memory leaks.
  //   if (this.textFile !== null) {
  //     window.URL.revokeObjectURL(this.textFile);
  //   }

  //   this.textFile = window.URL.createObjectURL(data);

  //   // returns a URL you can use as a href
  //   return this.textFile;
  // }

  // public async downloadTemplate() {
  //   const fields = this.fields.reduce((acc, value) => {
  //     if (value.key === 'actions') return acc;
  //     const field = value.key;
  //     return [field, ...acc];
  //   }, []);

  //   const data = this.respondents.map(respondent => {
  //     return fields.map((field: string) => {
  //       if ((respondent as any)[field]) {
  //         return (respondent as any)[field];
  //       }
  //       return '';
  //     });
  //   });

  //   const csv = Papa.unparse(
  //     { fields, data },
  //     {
  //       skipEmptyLines: true,
  //       header: true
  //     }
  //   );

  //   // const csv = csvRaw.filter(val => val !== 'headerPlaceHolder');
  //   const a = document.createElement('a');
  //   console.log(csv);
  //   a.href = this.createTextFile(csv);
  //   // a.href = !this.target ? '/csv/csv-import1.csv' : '/csv/csv-import2.csv';
  //   a.setAttribute('download', 'csv-import.csv');
  //   a.click();
  // }

  // private isComplete(respondent: Respondent) {
  //   const complete = Object.keys(respondent).every(key => {
  //     const value = (respondent as any)[key];
  //     return value !== '' && value !== null;
  //   });

  //   return complete && this.isEmail(respondent.email);
  // }

  // private isEmail(email: string) {
  //   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // }

  // private async parseCsv(csv: any): Promise<any[]> {
  //   const { data } = await new Promise((res, rej) => {
  //     try {
  //       Papa.parse(csv, {
  //         skipEmptyLines: true,
  //         worker: true,
  //         header: true,
  //         complete: res,
  //         error: rej
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       this.$bce.alert('Parse CSV', error);
  //       rej(error);
  //     }
  //   });

  //   const GENDER_MAP: { [key: string]: string } = {
  //     female: 'female',
  //     male: 'male',
  //     man: 'male',
  //     vrouw: 'female'
  //   };

  //   const TARGET_MAP: { [key: string]: string } = {
  //     entrepreneurs: 'entrepreneurs',
  //     headquarters: 'headquarters',
  //     hoofdkantoor: 'headquarters',
  //     ondernemers: 'entrepreneurs'
  //   };

  //   const parsed = data.map((entry: any) => {
  //     const target = (entry.target || entry.doelgroep || '').toLowerCase();
  //     const gender = (entry.gender || entry.geslacht || '').toLowerCase();

  //     return {
  //       name: entry.name || entry.naam,
  //       email: entry.email,
  //       gender: GENDER_MAP[gender] || '',
  //       target: TARGET_MAP[target] || 'entrepreneurs',
  //       ...entry
  //     };
  //   });
  //   const filtered = parsed.filter((entry: any) => !!entry.gender);
  //   console.log(parsed);
  //   if (parsed.length !== filtered.length) {
  //     this.$bce.message(
  //       'Dit bestand kan niet worden ingelezen. Het geslacht mist bij een aantal respondenten.',
  //       10,
  //       'error'
  //     );

  //     return [];
  //   }

  //   return filtered;
  // }

  // private empty(): Respondent {
  //   const target = this.target ? null : 'entrepreneurs';

  //   const newField = this.customFields.reduce((acc, val) => {
  //     acc[val.key] = '';
  //     return acc;
  //   }, {});

  //   return {
  //     id: this.$firebase.generateId(),
  //     rid: this.$route.params.id,
  //     uid: this.$user.id,
  //     name: '',
  //     gender: null as any,
  //     email: '',
  //     target: target as any,
  //     progress: 0,
  //     status: 'idle',
  //     ...newField
  //   };
  // }
}
