
















































































































































































































































































































































import { Research, Respondent, Target } from '@app/models';
import Papa, { ParseResult } from 'papaparse';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import ArrowUp from '../6-other/arrow-up.vue';

@Component({
  components: { ArrowUp },
})
export default class RespondentsTable extends Vue {
  @Prop()
  public allRespondents!: Respondent[];

  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public targetGroup!: Target | 'others';

  @Prop()
  public respondents!: Respondent[];

  @Ref('file-input')
  private file!: HTMLInputElement;

  public newCol: string = '';

  public currentPage = 1;
  public perPage = 10;

  public add: Respondent = {} as any;
  public edit: Respondent = {} as any;

  public textFile!: string | null;

  public dragIndex: null | number = null;
  public colEdit: any = {
    key: '',
    label: '',
    sortable: true,
    thClass: 'th-custom',
    tdClass: 'td-custom',
  };

  public get customFields(): Research.Field[] {
    return this.targetGroup !== 'others'
      ? this.value.customFields[this.targetGroup] || []
      : [];
  }

  public set customFields(value: Research.Field[]) {
    this.value.customFields = {
      ...(this.value.customFields || {}),
      [this.targetGroup]: value,
    };
  }

  public get formIsComplete() {
    return this.isComplete(this.add);
  }

  public get editIsComplete() {
    return this.isComplete(this.edit);
  }

  public get fields() {
    return [
      {
        key: 'email',
        label: this.$t('email'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      {
        key: 'first_name',
        label: this.$t('name-first'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      {
        key: 'name',
        label: this.$t('name-last'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      this.$FEATURES.languages.length > 1 && {
        key: 'language',
        label: this.$t('language'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
        formatter: (value?: string) => this.$t('language-' + (value || 'nl')),
      },
      {
        key: 'gender',
        label: this.$t('gender'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      {
        key: 'target',
        label: this.$t('target-audience'),
        sortable: true,
        thClass: 'th-fixed',
        tdClass: 'td-fixed',
      },
      ...this.customFields,
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions_',
        tdClass: 'td-actions',
      },
    ];
  }

  public get target() {
    return this.value.target.includes('headquarters');
  }

  public get currentTargetGroup() {
    return this.targetGroup === 'others'
      ? this.$FEATURES.target
      : this.$FEATURES.target.filter(t => t.startsWith(this.targetGroup));
  }

  public created() {
    this.add = this.empty();
    this.edit = this.empty();
  }

  public mounted() {
    this.setDraggeble();
  }

  @Watch('targetGroup')
  public watchTargetGroup() {
    this.add = this.empty();
  }

  public async toggleEditCol(key: string) {
    if (this.colEdit.key === key) {
      const index = this.customFields.findIndex(
        (field: any) => field.key === key
      );

      const batch = this.$firebase.batch();

      this.respondents.forEach(async (respondent: any) => {
        const pointer = respondent[this.colEdit.key] || '';

        respondent = {
          ...respondent,
          [this.colEdit.label]: pointer,
        };

        delete respondent[this.colEdit.key];

        const path = `research/${this.value.id}/respondent/${respondent.id}`;
        batch.set(this.$firebase.doc(path), respondent);
      });

      await batch.commit();

      this.colEdit.key = this.colEdit.label;

      this.customFields.splice(index, 1, this.colEdit);

      this.colEdit = {
        key: '',
        label: '',
        sortable: true,
        thClass: 'th-custom',
      };

      return;
    }

    this.colEdit = this.customFields.find((field: any) => field.key === key);
  }

  public setDraggeble() {
    const customCol = document.querySelectorAll('.th-custom');
    customCol.forEach(element => {
      if (!element.hasAttribute('draggable')) {
        element.addEventListener('dragstart', e => this.dragstart(e));
        element.addEventListener('drop', e => this.drop(e));
        element.addEventListener('dragover', e => this.allowDrop(e));
        element.setAttribute('draggable', 'true');
      }
    });
  }

  public allowDrop(ev: any) {
    ev.preventDefault();
  }

  public dragstart(ev: any) {
    const target = this.customFields;
    const label = ev.target.childNodes[0].innerHTML;
    const crt = ev.target.cloneNode(true);

    document.body.appendChild(crt);
    ev.dataTransfer.setDragImage(crt, 0, 0);
    ev.dataTransfer.dropEffect = 'move';

    const index = target.findIndex((field: any) => field.key.includes(label));

    this.dragIndex = index;
  }

  public drop(ev: any) {
    ev.preventDefault();
    const label = ev.target.childNodes[0].innerHTML;
    const index = this.customFields.findIndex((field: any) => {
      return field.key.includes(label);
    });

    if (!this.dragIndex) return;

    const dropEl = this.customFields.slice(this.dragIndex)[0];

    this.customFields.splice(this.dragIndex, 1);
    this.customFields.splice(index, 0, dropEl);

    this.dragIndex = null;
  }

  public async addCol() {
    const key = this.newCol;
    const newCol = {
      key: key,
      label: key,
      sortable: true,
      thClass: 'th-custom',
      tdClass: 'td-custom',
    };

    this.add = this.empty();
    this.edit = { ...this.edit, [key]: '' };
    this.newCol = '';
    this.customFields = [...this.customFields, newCol];

    this.setDraggeble();

    this.$emit('respondent:edit', true);
  }

  public async addRespondent() {
    if (this.respondents.find(r => r.email === this.add.email)) {
      this.$bce.message(this.$t('user-is-already-added'));
      this.add = this.empty();
      return;
    }

    await this.$firebase
      .doc(`research/${this.value.id}/respondent/${this.add.id}`)
      .set(this.add);

    this.add = this.empty();
  }

  public async deleteRespondent(respondent: Respondent) {
    await this.$firebase
      .doc(`research/${this.value.id}/respondent/${respondent.id}`)
      .delete();
  }

  public async editRespondent(respondent: Respondent) {
    this.edit = { ...respondent };
    this.$emit('respondent:edit', true);
  }

  public async updateRespondent(respondent: Respondent) {
    await this.$firebase
      .doc(`research/${this.value.id}/respondent/${respondent.id}`)
      .set(respondent);

    this.edit = this.empty();
    this.$emit('respondent:edit', false);
  }

  public async csvChoose() {
    this.file.click();
  }

  public async csvDownload() {
    const a = document.createElement('a');
    a.href = `/file/respondent-template-${this.$customerNameShort}.xlsm`;
    a.setAttribute('download', 'respondent-template.xlsm');
    a.click();
  }

  public async csvImport(event: Event) {
    const file = this.file.files && this.file.files[0];
    if (!file) return;

    const data = await this.csvParse(file);
    this.file.value = '';
    if (!data.length) return;

    for (const key of Object.keys(data[0])) {
      if (this.fields.find(field => field && field.key === key)) continue;
      const field: Research.Field = { key, label: key, sortable: true };
      this.customFields = [...this.customFields, field];
    }

    const batch = this.$firebase.batch();
    for (const entry of data) {
      const existing = this.allRespondents.find(r => r.email === entry.email);
      const respondent: Respondent = existing
        ? { ...existing, ...entry }
        : { ...this.empty(), ...entry };

      const path = `research/${this.value.id}/respondent/${respondent.id}`;
      batch.set(this.$firebase.doc(path), respondent);
    }

    await batch.commit();
    this.$bce.message(this.$t('csv-file-imported'), 5, 'primary');
  }

  private async csvParse(csv: any): Promise<any[]> {
    const result = await new Promise<ParseResult<any>>((res, rej) => {
      try {
        Papa.parse(csv, {
          skipEmptyLines: true,
          worker: true,
          header: true,
          complete: res,
          error: rej,
        });
      } catch (error) {
        console.log(error);
        this.$bce.message(this.$t('csv-file-could-not-be-read'), 10, 'error');
        rej(error);
      }
    });

    const GENDER_MAP: { [key: string]: string } = {
      female: 'female',
      male: 'male',
      man: 'male',
      vrouw: 'female',
    };

    const parsed = result.data
      .map(entry => {
        const target = (entry.target || entry.doelgroep || '').toLowerCase();
        const gender = (entry.gender || entry.geslacht || '').toLowerCase();

        return {
          ...entry,
          name: entry.name || entry.naam,
          email: entry.email || entry['e-mail'],
          gender: GENDER_MAP[gender] || '',
          target: this.$FEATURES.target_map[target] || '',
        };
      })
      .filter(entry => !!entry.email);

    let errorEmail = false;
    let errorGender = false;
    let errorTarget = false;

    const filtered = parsed.filter(entry => {
      const email = !this.isEmail(entry.email);
      const gender = !entry.gender;
      const target =
        this.targetGroup !== 'others' &&
        !entry.target.startsWith(this.targetGroup);

      errorEmail = errorEmail || email;
      errorGender = errorGender || gender;
      errorTarget = errorTarget || target;
      return !email && !gender && !target;
    });

    if (errorEmail) {
      this.$bce.message(this.$t('csv-file-bad-email'), 10, 'error');
      return [];
    } else if (errorGender) {
      this.$bce.message(this.$t('csv-file-bad-gender'), 10, 'error');
      return [];
    } else if (errorTarget) {
      this.$bce.message(this.$t('csv-file-bad-target'), 10, 'error');
      return [];
    } else if (parsed.length !== filtered.length) {
      this.$bce.message(this.$t('csv-file-could-not-be-read'), 10, 'error');
      return [];
    }

    return filtered;
  }

  public createTextFile(text: string) {
    var data = new Blob([text], { type: 'text/plain' });

    // If we are replacing a previously generated file we need to manually
    // revoke the object URL to avoid memory leaks.
    if (this.textFile !== null) {
      window.URL.revokeObjectURL(this.textFile);
    }

    this.textFile = window.URL.createObjectURL(data);

    // returns a URL you can use as a href
    return this.textFile;
  }

  private isComplete(respondent: Respondent) {
    const PREDEFINED = ['email', 'name_first', 'name', 'gender', 'target'];
    const fields = this.customFields.map(field => field.key);

    const complete = [...PREDEFINED, ...fields].every(key => {
      const value = (respondent as any)[key];
      return value !== '' && value !== null;
    });

    return complete && this.isEmail(respondent.email);
  }

  private isEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public getCategory(name: string) {
    return this.$company.categories.find(c => c.name === name);
  }

  private empty(): Respondent {
    const newField = this.customFields.reduce((acc: any, val: any) => {
      acc[val.key] = '';
      return acc;
    }, {});

    const targets = this.$FEATURES.target;
    const respondent: Respondent = {
      id: this.$firebase.generateId(),
      rid: this.$route.params.id,
      company: this.$company.id!,
      first_name: '',
      name: '',
      gender: null as any,
      email: '',
      target:
        this.targetGroup !== 'others'
          ? targets.find(t => t.startsWith(this.targetGroup)) || ('' as any)
          : ('' as any),
      progress: 0,
      status: 'idle',
    };

    return { ...respondent, ...newField };
  }
}
