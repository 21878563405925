


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ResearchTargetTabs extends Vue {
  @Prop()
  public value!: string;

  @Prop()
  public targets!: string[];
}
